@use "../../../statics/css/theme.scss" as theme;

.button {
  width: 100%;
  height: 2.8rem;
  color: theme.$light;
  background: theme.$primary;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0.3rem;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
  font-weight: 700;
}

.button[disabled="false"]:hover {
  background: theme.$main;
}

.button:disabled {
  cursor: default;
  opacity: 0.6;
}
