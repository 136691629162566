@use "../../statics/css/theme.scss" as theme;

.input-container {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.label {
  font-weight: 600;
  font-size: 0.9rem;
  color: theme.$primary;
}

.label[data-active="true"] {
  color: theme.$error;
}

.label > span {
  position: relative;
  z-index: 10;
}

.label > span > span {
  margin-right: 0.25rem;
  color: theme.$error;
}
