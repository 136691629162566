@use "./theme.scss" as theme;

// text
.primary {
  color: theme.$primary;
}
.error {
  color: theme.$error;
}

.base {
  color: theme.$base;
}

// icons
.light-icon {
  fill: theme.$primary;
}

.col-span-full {
  grid-column: 1/-1;
}

.col-span-2 {
  grid-column: 1/3;
}
