@use "../index.scss";
@use "../../../statics/css/theme.scss" as theme;

input[type="range"] {
  width: 100%;
  cursor: pointer;
  width: 100%;
  height: 8px;
  background: theme.$primary;
  -webkit-appearance: none;
  border-radius: 4px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 25px;
  background: theme.$primary;
  -webkit-appearance: none;
  position: relative;
}

input[type="range"]::-webkit-slider-thumb::after {
  content: "";
  width: 400px;
  height: 400px;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

input[type="range"][data-active="true"] {
  color: theme.$error;
}

.error {
  margin-left: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
  max-width: 100%;
  color: theme.$error;
}
