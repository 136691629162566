@use "../../statics/css/theme.scss" as theme;

.next-patient {
  display: flex;
  justify-content: space-between;
  & p {
    margin-top: 12px;
  }
  & p:first-child {
    color: theme.$primary;
    font-weight: bold;
  }
}

.lg {
  font-size: 1.825rem;
}

.sm {
  font-size: 1.25rem;
}
