@use "../index.scss";
@use "../../../statics/css/theme.scss" as theme;

select {
  text-align: center;
  width: 100%;
  height: 2.8rem;
  border-radius: 0.375rem;
  border: 1px solid theme.$primary;
  outline: "none";
  font-size: 1rem;
  color: theme.$primary;
  transition: 0.3s;
}

select:hover,
select:focus {
  border-color: theme.$primary;
  border-width: 2px;
}

select:disabled:hover {
  border-width: 1px;
}

select[data-active="true"] {
  border-color: theme.$error;
}

.error {
  margin-left: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
  max-width: 100%;
  color: theme.$error;
}
