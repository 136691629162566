@use "../../statics/css/theme.scss" as theme;

.modal-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000;
}

.modal {
  background: theme.$light;
  padding: 40px 20px 20px 20px;
  width: 100%;
  max-width: 800px;
  border-radius: 4px;
  box-shadow: theme.$shadow-sm;
  border: 1px solid var(--p01);
  max-height: 650px;
  overflow-y: auto;
  position: relative;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.close-icon > * {
  fill: theme.$primary;
}
