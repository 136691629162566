@use "../../statics/css/theme.scss" as theme;

.patient-container {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  animation: appear linear forwards;
  animation-duration: 0.5s;
  & h2 {
    font-weight: normal;
    font-size: 2.2em;
    margin-top: 20px;
    text-align: center;
    color: theme.$primary;
  }
  & .patient-information-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 10px dotted theme.$base;
    &.removeBorder {
      border: 0px;
    }
    & .patient-item {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 2.2em;
    }
  }
}

.loadingAnimation {
  animation: appear2 linear infinite;
  animation-duration: 2s;
}

@keyframes appear2 {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
