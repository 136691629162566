@use "../index.scss";
@use "../../../statics/css/theme.scss" as theme;

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0px 4px;
  border-radius: 0.375rem;
  border: 1px solid theme.$primary;
  outline: "none";
  font-size: 1rem;
  color: theme.$primary;
  transition: 0.3s;
}

input:hover,
input:focus {
  border-color: theme.$primary;
  border-width: 2px;
}

input:disabled:hover {
  border-width: 1px;
}

input[data-active="true"] {
  border-color: theme.$error;
}

.error {
  margin-left: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
  max-width: 100%;
  color: theme.$error;
}
