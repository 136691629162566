$dark-theme: false !default;

$main: #3d2ab6;
$primary: #5a46dd;
$light: #ffffff;
$base: #253948;
$error: #ef4444;

$shadow-sm: 0px 1px 2px rgba(0, 0, 0, 0.1);

@if $dark-theme {
  // Change colors in variables
}
