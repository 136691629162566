@use "./theme.scss" as theme;

.container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 100vh;
}
button{
  cursor: pointer;
  &:hover{
    opacity: 0.6;
  }
}
.queue-container {
  display: flex;
  max-width: 60em;
  padding: 1em;
  flex-direction: column;
  flex-grow: 1;
}

.center {
  margin: 0 auto;
}

.labels {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.5rem;
  color: theme.$base;
  margin-top: 20px;
}

.queue-history {
  background: theme.$light;
  padding: 20px 10px;
}

.queue-history > h2 {
  color: theme.$base;
  font-size: 1.825em;
  font-weight: normal;
  text-align: center;
  margin-bottom: 20px;
}

.title,
.subtitle {
  color: theme.$base;
  font-size: 3em;
  font-weight: normal;
  text-align: center;
}
.error {
  font-size: 3em;
  color: theme.$error;
}
.subtitle {
  text-align: left;
  font-size: 2.2em;
  margin-top: 20px;
}

.titleAnimation {
  opacity: 0;
  animation: appear linear forwards;
  animation-duration: 0.3s;
}

.selection-title {
  font: 32px 700;
  color: theme.$primary;
  text-align: center;
  margin: 20px 0;
}

.fields-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1.25rem;
}

.test-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  align-items: end;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
