@use "./theme.scss" as theme;

// resets
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "rubik", sans-serif;
}

img {
  max-width: 100%;
}

body {
  color: theme.$light;
  height: 100vh;
  background-image: url("../imgs/bg.png");
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  // backdrop-filter: blur(50px);
  &::before {
    content: "";
    background: white;
    opacity: 0.97;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
  }
}
