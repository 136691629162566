@use "../../statics/css/theme.scss" as theme;
.footer-container {
  position: fixed;
  bottom: 43px;
  width: 100%;
  height: 60px;
  & .powered {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: theme.$primary;
    padding: 10px;
  }
  & .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: theme.$primary;
    & .footer-logo {
      display: flex;
      // align-items: center;
      width: 100%;
      gap: 20px;
      max-width: 1000px;
      position: relative;
      background-color: white;
      padding-top: 12px;
      padding-left: 12px;
      clip-path: polygon(0 0, 94% 0, 100% 100%, 0% 100%);
    }
    & .footer-informations {
      display: flex;
      gap: 40px;
      font-size: 2.2em;
      & .footer-icon {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: 10px;
      }
    }
  }
}
